<template>
    <div>
        <div class="terminal_page">

            <template v-if="admin">
                <div class="grid-layout-container alignment-block">
                    <vs-row>
                        <vs-col vs-w="3" vs-xs="12" vs-sm="6">
                            <BoxVente
                                title="Ventes séances"
                                :value="millier(vente_seance.toFixed(2))+' €'"
                                background="white"
                                textColor="rgb(109, 173, 249)"
                            />
                        </vs-col>
                        <vs-col vs-w="3" vs-xs="12" vs-sm="6" class="btn_sale">
                            <BoxVente
                                title="Ventes accessoires"
                                :value="millier(vente_accesoire.toFixed(2))+' €'"
                                background="white"
                                textColor="rgb(92, 184, 92)"
                            />
                        </vs-col>
                        <vs-col vs-w="3" vs-xs="12" vs-sm="6" class="btn_sale">
                            <BoxVente
                                title="Chiffre affaire mensuel"
                                :value="millier(vente_ca_mensuel.toFixed(2))+' €'"
                                background="white"
                                textColor="rgb(240, 173, 78)"
                            />
                        </vs-col>
                        <vs-col vs-w="3" vs-xs="12" vs-sm="6" class="btn_sale">
                            <BoxVente
                                title="Chiffre affaire annuel"
                                :value="millier(vente_ca_annuel.toFixed(2))+' €'"
                                background="white"
                                textColor="rgb(217, 83, 79)"
                            />
                        </vs-col>
                    </vs-row>

                </div>
                <br/>

                

                <vs-row>
                    <vs-col vs-w="6" vs-xs="12" vs-sm="6">
                        <vs-row>
                            <vs-col vs-w="4" vs-xs="12" vs-sm="4" class="container center">
                                <vx-card class="text-center center" v-on:click="changePage('/admin/facturesView/')">
                                    <vs-button radius color="primary" type="gradient" icon-pack="feather" icon="icon-eye" class="center"></vs-button>
                                    <h4>Factures</h4>
                                </vx-card>
                            </vs-col>
                            <vs-col vs-w="4" vs-xs="12" vs-sm="4" class="container center">
                                <vx-card class="text-center center" v-on:click="changePage('/admin/teamView/')">
                                    <vs-button radius color="warning" type="gradient" icon-pack="feather" icon="icon-message-square" class="center"></vs-button>
                                    <h4>Équipe</h4>
                                </vx-card>
                            </vs-col>
                            <vs-col vs-w="4" vs-xs="12" vs-sm="4" class="container center">
                                <vx-card class="text-center center" v-on:click="changePage('/admin/sellsView/')">
                                    <vs-button radius color="danger" type="gradient" icon-pack="feather" icon="icon-shopping-bag" class="center"></vs-button>
                                    <h4>Ventes</h4>
                                </vx-card>
                            </vs-col> 
                        </vs-row>
                    </vs-col>
                    <vs-col vs-w="6" vs-xs="12" vs-sm="6">
                        <vx-card>
                                <h4>Date</h4>
                                <cDate month="1" v-model="list_date" class="w-full" />
                        </vx-card>
                    </vs-col>
                </vs-row>
                <br/>

            </template>
            <template v-else>
                <!-- <h2>Accés non autorisé.</h2> -->
                 <vx-card>
                    <h4>Date</h4>
                    <cDate month="1" v-model="list_date" class="w-full" />
                 </vx-card>
            </template>
        </div>
    <div>
       
        <router-view  ref="monComp" :shared="$data" :parents="this"  ></router-view>
        
    </div>
  </div>
</template>



<style lang="scss" scoped >



.btn_sale{
    text-align:center;
    p{
        font-size:2em;
    }
    .vx-card__title,.vx-card__header,.vx-card{
        text-align:center !important;
        margin:auto;
    }
    .vx-card{
    margin-left:10px !important;
    margin-right:10px !important;
    }
}
.nowrap{
    white-space:nowrap;
}
.blue{
    color:blue;
}

@media only screen and (max-width: 900px)
{
    .filtre .container{
        margin-bottom:20px !important;
    }
}
</style>

<script>
//import autoRefresh from '@/assets/utils/autoRefresh'

import Utils from '@/assets/utils/utils'
import Fdate from '@/assets/utils/fdate'


import Terminal from '@/database/models/terminal'
import Invoice from '@/database/models/invoice'
import Item from '@/database/models/item'
import Member from '@/database/models/member'

import BoxVente from '@/components/app/boxVente'
import BoxInvoicing from '@/components/app/boxInvoicing'
import cDate from '@/components/app/date'
import VxCard from '../../components/vx-card/VxCard.vue'
import Fact from '@/views/page/facturationViews/factures.vue'


export default {
    components: {
        BoxVente,
        cDate,
        BoxInvoicing,
        VxCard,
        Fact,
    },
    data(){
        return{
            
            toFacture:false,
            terminal_uid :'?',
            terminal_name:'?',
            terminal_list:[],

            // test transfert
            nameTitle:'imane',
            //

            list_facture:[],
            selected:[],

            list_type:"all",

            list_date: new Date(),

            current:' ',

            vente_seance:0,
            vente_accesoire:0,
            vente_ca_mensuel:0,
            vente_ca_annuel:0,

            

            member_type:"",
            admin:false,
        }
    },
    watch:{
        list_date:function(){
            this.changeDate()
            //console.log("list date changed ", this.$route);
            if (typeof(this.$refs.monComp)==='undefined' || this.$refs.monComp === null){
                //console.log(" ref undefined ");
            }else{
                this.$refs.monComp.changeDate( this.$data);

            }
            //this.log();
        },
        list_type:function(){
            //console.log("list type changed ")
            if (typeof(this.$refs.monComp)==='undefined' || this.$refs.monComp === null){
                //console.log(" ref undefined ");
            }
            else{
                this.$refs.monComp.changeType(this.$data);
            }
        },
        terminal_uid:function(){
            //console.log("termainal changed ", this.$refs.monComp)
            //console.log(" -- - -- - ", this.$refs.monComp);


            if (typeof(this.$refs.monComp)==='undefined' || this.$refs.monComp === null){
                //console.log(" ref undefined ");
            }
            else{
                this.$refs.monComp.changeTerminal(this.terminal_uid);
            }

        },



    },
	mounted:function()
	{
        //console.log(" -- - -- - ", this.$refs.monComp);
        
        this.RoleAdmin();
        ListRefresh(this);
        frefresh(this);
        
        
	},
    methods:{
        changePage:function( url )
        {
            
            this.$router.push( url + this.terminal_uid ).catch(() => {});
        },

        millier:Utils.Millier,
        //change terminal
        changePath(){
            //console.log("ok");
            this.current=this.$route;
        },
        changeTerminal:function()
        {

            //change terminal page
            if(this.$route.params.uid != this.terminal_uid)
            {
                this.$router.push('/admin/facturationVH/'+this.terminal_uid);
                this.list_facture = [];
                frefresh(this);
            }

            return;
        },
        essai(){
            //console.log(" -> ", this.$refs.monComp);
        },
        log(){
            //console.log("changes send ");
        },
        changeDate:function(){
            frefresh(this)

        },
        changeType(){
            frefresh(this)
        },
        refresh:function(){
            ListRefresh(this);
        },
        handleSelected()
        {
            //voir facture
            this.$router.push('/admin/invoice/'+this.selected.uid);
        },


        RoleAdmin()
        {
            this.admin = false;
                  //Chargement info user
            let uid = this.$srvApi.getLoginUserUid();
            Member.getMemory(uid, (mbr)=>
            {
                this.member_type = mbr.type.toLowerCase()
                this.admin = mbr.type.toLowerCase() == "a";
            });
        }
    }
};

//refresh list terminal
function ListRefresh(root)
{
    //---------------------------------
    //liste terminal
    Terminal.getAllMemory( function(fbuf)
    {
        let tab = [];
        //global terminal
        tab.push({
            value : 'all',
            text  : 'Tout',
        });

        //add terminal
        for( const i in fbuf )
        {
            //add change
            tab.push({
                value : fbuf[i].uid,
                text  : fbuf[i].mac,
            });

            //find actu
            if( root.$route.params.uid==fbuf[i].uid )
            {
                root.terminal_uid  = fbuf[i].uid;
                root.terminal_name = fbuf[i].mac;
            }
            if(root.$route.params.uid == 'all')
            {
                root.terminal_uid  = 'all';
                root.terminal_name = 'Tout';
            }
        }
        root.terminal_list = tab;
    });
}


//Facture in terminal
function frefresh(root)
{
    //---------------------------------
    //List facture
    Invoice.getAllMemory( function(fbuf)
    {
        //vider mémoire
        root.list_facture = [];

        //stats
        state(fbuf, root)

        //complete data
        function completeDate(buffer)
        {
            //filtre terminal
            if(buffer.terminal!=root.$route.params.uid )
            if(root.$route.params.uid!='all')   // si j'utilise store je fais comment ?
                return

            //filtre verif type
            if(root.list_type != "all") // la ca serait maVar.$state.list_type
            if(buffer.type != root.list_type )
                return

            //filtre par date
            if(root.list_date != null)
            {
                let tfmin = (new Date( root.list_date.getFullYear(), root.list_date.getMonth(), 1 )).getTime()
                let tfmax = (new Date( root.list_date.getFullYear(), root.list_date.getMonth()+1, 1 )).getTime()
                let facd  = (new Date(buffer.createAt)).getTime()
                if( facd < tfmin )
                    return
                if( facd > tfmax)
                    return
            }


            Member.getMemory( buffer.member, function(mbr)
            {
                //Si pas de membre ?
                let p_member = { first_name:'Aucun', last_name:'' };
                if(mbr)
                    p_member = mbr;

                //get vendor
                Member.getMemory( buffer.vendor, function(ved)
                {
                    let p_vendor = { first_name:'Aucun', last_name:'' };
                    if(ved)
                        p_vendor = ved;

                    //payement
                    let paye = []
                    if(buffer.pay_money!=0)
                        paye.push({name:'ESP', value:buffer.pay_money})

                    if(buffer.pay_cb!=0)
                        paye.push({name:'CB',value:buffer.pay_cb})

                    if(buffer.pay_check!=0)
                        paye.push({name:'CHEQ',value:buffer.pay_check})

                    if(buffer.pay_dif!=0)
                        paye.push({name:'DIFF',value:buffer.pay_dif})

                    if(buffer.pay_prele!=0)
                        paye.push({name:'PREL',value:buffer.pay_prele})

                    //Item
                    Item.getTabAllMemoryKey( buffer.uid, function(items)
                    {
                        //add
                        root.list_facture.push({   // root = this <=> $state.list_facture
                            uid      : buffer.uid,
                            type     : Invoice.getTypeName(buffer),
                            date     : Fdate.getFullDateMin(buffer.createAt,true)+" "+Fdate.getHeure(buffer.createAt),
                            vdate    : Fdate.getFullDateMin(buffer.createAt),
                            number   : buffer.number,
                            vendor   : {'first_name':Utils.StrCapital(p_vendor.first_name), 'last_name':Utils.StrCapital(p_vendor.last_name)},
                            member   : {'first_name':Utils.StrCapital(p_member.first_name), 'last_name':Utils.StrCapital(p_member.last_name)},
                            item     : items,
                            payement : paye,
                            total    : (buffer.pay_cb + buffer.pay_money + buffer.pay_check + buffer.pay_dif + buffer.pay_prele).toFixed(2),
                        });
                    })
                });
            });
        }

        //lister facture
        for( var i in fbuf )
            completeDate(fbuf[i]);
    });
}

//  faire que ces fonctions appel une mutation ?

function state( list_fac, root ){

    let date_actu = new Date(root.list_date)

    let Amin = (new Date( date_actu.getFullYear(), 0, 1, 0, 0, 0 )).getTime()
    let Amax = (new Date( date_actu.getFullYear()+1, 0, 1, 0, 0, 0 )).getTime()

    let Mmin = (new Date( date_actu.getFullYear(), date_actu.getMonth(), 1, 0, 0, 0 )).getTime()
    let Mmax = (new Date( date_actu.getFullYear(), date_actu.getMonth()+1, 1, 0, 0, 0)).getTime()

    //reset box state
    root.vente_ca_annuel = 0
    root.vente_ca_mensuel = 0
    root.vente_seance = 0
    root.vente_accesoire = 0

    for( var i in list_fac )
    {
        //get facture
        let buffer = list_fac[i]
        let total = (buffer.pay_cb + buffer.pay_money + buffer.pay_check + buffer.pay_dif + buffer.pay_prele)

        let facd  = (new Date(buffer.createAt)).getTime()

        //Calcule CA anné
        if(facd>=Amin)
        if(facd<=Amax)
            root.vente_ca_annuel += total

        //Calcule CA mois
        if(facd>=Mmin)
        if(facd<=Mmax)
        {
            //CA mois
            root.vente_ca_mensuel += total
        }
    }

    //Item
    Item.getAllMemory( function(items)
    {
        for( var p in items)
        {
            let buffer = items[p]
            let itdate  = (new Date(buffer.createAt)).getTime()

            //Calcule CA mois
            if(itdate>=Mmin)
            if(itdate<=Mmax)
            if(buffer.prix!=0)
            if(buffer.tva!=0)
            if(buffer.type!='re')
            {
                let total = 0
                //Si Facture
                if( buffer.type =='fa' || buffer.type =='re' )
                    total = parseFloat((buffer.prix * ((buffer.tva/100)+1)).toFixed(2))

                //Sinon avoir
                if( buffer.type =='av' )
                    total = parseFloat((buffer.prix * (( (buffer.tva*-1)/100)+1)).toFixed(2))

                if( buffer.credit!=0)
                    root.vente_seance += total
                else
                    root.vente_accesoire += total

            }
        }
    })
}

</script>
