<template>
    
        <div class="boxInvoicing" v-bind:style="fbackground()" id="siActif">
                <div>
                    <h5  > <router-link :to="linkGo" v-bind:style="fTxtColor()" > {{ title }}</router-link> </h5>
                </div>
                
        </div>
    
   
</template>

<style lang="scss">
.boxInvoicing{
    margin:10px;
    text-align:center;
    border-radius:5px;
    width: 150px;
    border: 0.5px solid grey;
        
    h5{
        padding-top:5px;
        height:30px;
    }
    h3{
        padding-bottom:5px;
        height:40px;
    }
    .router-link-exact-active{
        background-color: pink;
    }
    
}



</style>

<script>
export default {
    props: {
        title:{
            type:String,
            default: '',
        },
        linkGo:{
            type:String, 
            default:'/admin/facturesView',
        },
        background:{
            type:String,
            default: 'white',
        },
        textColor:{
            type:String,
            default:'grey'
        },
       
    },
    data(){
        return{
            
        }
    },
    watch:{
        $route (){
            
        },
        
    },
    mounted:function(){
    },
    methods:{
        fbackground(colo){
            if(colo==null){
                //console.log(" ici cici ", this.linkGo)
                return {
                    'background-color': this.background,
                }
            }
            else{
                return {
                    'background-color': colo,
                
                }
            }
        },
        fTxtColor(){
            return {
                'color': this.textColor
            }
        },
        // getElement(){
        //     this.$router.push({path:this.linkGo})
        // }
        // changeColor(name){
        //     let newColor = 'pink'
        //     if(this.title!=name){
        //         return {
        //             'background-color ': this.background , 
        //         }
        //     }
        //     else{
        //         return{
        //             'backgroung-color': newColor, 'color': this.background,
        //         }
        //     }
        // }
    }
}
</script>